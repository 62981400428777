<template>

  <div>
    <ft-select @input="changeMono($event)" :items="CHOICE_ACTIVE" v-model="stripeOn" class="pointer">
      <v-chip
          dark
          :color="wsACCENT"
          :style="!stripeOn ? 'opacity : 0.5' : ''"
          class="px-1 justify-end font-weight-medium pointer"
      >

        <v-icon  class="mr-1" >
          {{  stripeOn ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
        </v-icon>

        <h5 class="mx-2">{{ stripeOn ? $t('Active') : $t('NotActive')   }}</h5>

        <v-icon >mdi-menu-down</v-icon>

      </v-chip>
    </ft-select>

    <wsDialog v-if="displayMono"
              v-model="displayMono"
              :title="`${$t('Settings')} Portmone`"
              @save="linkFondy"
              :loading="LOADING"
              :save-text="$t('ConfirmLink')">

      <ws-text-field
          v-model="fondy.login"
          :placeholder="$t('PortmoneLogin')"
          label="Portmone Login"
          dense
          outlined
          hide-details
      />
      <ws-text-field
          class="mt-5"
          v-model="fondy.payee_id"
          :label="$t('PortmonePayee')"
          :placeholder="$t('EnterPortmonePayee') "
          dense
          outlined
          hide-details
      />
      <ws-text-field
          class="mt-5"
          v-model="fondy.key"
          :label="$t('PortmoneKey')"
          :placeholder="$t('EnterPortmoneKey') "
          dense
          outlined
          hide-details
      />

    </wsDialog>

  </div>



</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "liqPaySettings",
  props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      stripeOn : false,
      displayMono : false,
      fondy : {
        account   : null,
        secret : null
      }
    }
  },
  watch: {
    value() {
      if ( this.value !== this.stripeOn ) {
        this.stripeOn = this.value
      }
    }
  },
  methods : {
    ...mapActions('portmone', [
      'LINK_BUSINESS',
      'UNLINK_BUSINESS'
    ]),

    async changeMono(value) {

      if ( !value ) {
        return this.unlinkFondy()
      }

      this.stripeOn = false
      this.liqPay = {
        public_key   : null,
        private_key  : null,
      }
      this.displayMono = true

    },

    async unlinkFondy() {

      if (await this.UNLINK_BUSINESS() ) {
        this.notify(this.$t('PortmoneUnlinked'))
        this.$emit('input',false)
        return
      }
    },
    async linkFondy() {

      if ( !this.fondy.login || !this.fondy.payee_id || !this.fondy.key ) {
        this.notify(this.$t('WrongPortmoneData'))
        return
      }
      if (!await this.LINK_BUSINESS(this.fondy) ) {
        return this.notify(this.$t('WrongPortmoneData'))
      }

      this.$emit('input',true)
      this.notify( this.$t('PortmoneConnected') , 'success' )
      this.displayMono = false

    },
  },
  mounted() {
    if ( this.value ) {
      this.stripeOn = this.value
    }
  }
}
</script>

<style scoped>

</style>