<template>

  <div>
    <ft-select @input="changeLiqPay($event)" :items="CHOICE_ACTIVE" v-model="liqPayOn" class="pointer">
      <v-chip
              dark
              :color="wsACCENT"
              :style="!liqPayOn ? 'opacity : 0.5' : ''"
              class="px-1 justify-end font-weight-medium pointer"
      >

        <v-icon  class="mr-1" >
          {{  liqPayOn ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
        </v-icon>

        <h5 class="mx-2">{{ liqPayOn ? $t('Active') : $t('NotActive')   }}</h5>

        <v-icon >mdi-menu-down</v-icon>
        
      </v-chip>

    </ft-select>

    <wsDialog v-if="displayLiqPay"
              v-model="displayLiqPay"
              :title="$t('LiqPaySettings')"
              @save="linkLiqPay"
              :loading="LOADING"
              :save-text="$t('ConfirmLink')">

      <ws-text-field
          v-model="liqPay.public_key"
          :placeholder="$t('PublicKey') "
          :label="$t('PublicKey')"
      />
      <ws-text-field
          v-model="liqPay.private_key"
          :placeholder="$t('PrivateKey') "
          :label="$t('PrivateKey')"
          class="mt-5"
      />

      <div class="d-flex mt-6">
        <v-btn :color="liqPayConfirm ? 'green lighten-1' : ''" class="mr-2" @click="liqPayConfirm = !liqPayConfirm" icon >
          <v-icon >{{ liqPayConfirm ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
        </v-btn>
        <h5 class="font-weight-light">Я підтверджую, що в моєму LiqPay акаунті не підключено додаткові функції проведення зовнішніх платежів</h5>
        <ws-tooltip text="В стандартному акаунті LiqPay відключено функцію зовнішніх платежів через API, тому користуючись API ключами можливо тільки виставляти рахунки клієнтам. Для безпеки вашого рахунку, Fine Trading рекомендує використовувати API ключі без можливості зовнішніх платежів. У разі якщо ви користуєтесь додатковим API LiqPay, в якому є можливість здійснення зовнішніх платежів, рекомендуємо створити додатковий LiqPay акаунт без таких функцій, та використовувати його з WeStudy"  >
              <v-icon color="grey lighten-1"> mdi-information </v-icon>
        </ws-tooltip>
      </div>




    </wsDialog>

  </div>



</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "liqPaySettings",
  props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      liqPayOn : false,
      displayLiqPay : false,
      liqPay : {
        public_key   : null,
        private_key  : null,
      },
      liqPayConfirm  : false,
    }
  },
  watch: {
    value() {
      if ( this.value !== this.liqPayOn ) {
        this.liqPayOn = this.value
      }
    }
  },
  methods : {
    ...mapActions('liqpay', [
      'LINK_BUSINESS',
      'UNLINK_BUSINESS'
    ]),

    async changeLiqPay(value) {

      if ( !value ) {
        return this.unlinkLiqPay()
      }

      this.liqPayOn = false
      this.liqPay = {
        public_key   : null,
        private_key  : null,
      }
      this.displayLiqPay = true

    },

    async unlinkLiqPay() {

      if (await this.UNLINK_BUSINESS() ) {
        this.notify(this.$t('LiqPayUnlinked'))
        this.$emit('input',false)
        return
      }
    },
    async linkLiqPay() {

      if (!this.liqPayConfirm) {
        return this.notify(this.$t('ApproveLiqpayCondition'))
      }

      if (! await this.LINK_BUSINESS(this.liqPay) ) {
        return this.notify(this.$t('WrongLiqPayKeys'))
      }

      this.$emit('input',true)
      this.notify( this.$t('LiqPayConnected') )
      this.displayLiqPay = false

    },
  },
  mounted() {
    if ( this.value ) {
      this.liqPayOn = this.value
    }
  }
}
</script>

<style scoped>

</style>